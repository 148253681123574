import React, {useEffect, useState} from "react";
import EventIcon from "@material-ui/icons/Event";
import Button from "@material-ui/core/Button";
import { atcb_action, atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css'
import "./style.styl";


export default function AddToCalendarButton(props) {
    const {event, className, size} = props;

    return (
      <Button aria-label="Add Event To Your Personal Calendar" className={className || "button-secondary"} size={size} variant="outlined"
              onClick={() => {
                const startAt = new Date(event.start_at);
                startAt.setHours(0, 1);
                const endAt = new Date(event.end_at);
                endAt.setHours(23, 59);

                const startDate = new Date(startAt);
                startDate.setHours(12, 0);
                const endDate = new Date(endAt);
                endAt.setHours(12, 0);
                const calendarEvent = {
                    "name": "Piroshky Piroshky Pick up " + (event.name || ""),
                    "description": event.description || "",
                    "startDate": startDate.toISOString().split("T")[0],
                    "endDate": endAt.toISOString().split("T")[0],
                    "location": (event.address || "") + ", " + (event.city || "") + ", " + (event.state || "") + ", " + (event.postal_code || ""),
                    "options":[
                      "Apple",
                      "Google",
                      "Microsoft365",
                      "MicrosoftTeams",
                      "Outlook.com",
                      "Yahoo",
                      "iCal"
                    ],
                    "timeZone": Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : undefined,
                    "inline":true,
                    "listStyle":"modal",
                    "trigger":"click",
                    "iCalFileName":"Piroshky-Event"
                }
                atcb_action(calendarEvent);
              }}>
          <EventIcon fontSize={size === "large" ? "medium" : size} className="u-textColorNormal u-marginRight8"/> Add to Calendar
      </Button>
    );
}
